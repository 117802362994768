import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/header.module.css'; // Import CSS module

const Header = () => {
    return (
        <>
            <header className={styles.header}>
                <div className={styles.headerContainer}>
                    <Link to="/">
                        <img src="/logo.png" alt="Izmir City Vision Logo" className={styles.logo} />
                    </Link>
                    <nav className={styles.navLinks}>
                        <ul>
                            <li>
                                <Link to="/" className={styles.activeNavLink}>
                                    <img src="/homeicon.svg" alt="Home Icon" className={styles.navIcon} />
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/discover" className={styles.navLink}>
                                    <img src="/discovericon.svg" alt="Discover Icon" className={styles.navIcon} />
                                    <span>Discover</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/map" className={styles.navLink}>
                                    <img src="/map.svg" alt="Map Icon" className={styles.navIcon} />
                                    <span>Map</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/events" className={styles.navLink}>
                                    <img src="/event.svg" alt="Event Icon" className={styles.navIcon} />
                                    <span>Events</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <Link to="/contact" className={styles.navLink}>
                        <button className={styles.contactus}>Contact Us</button>
                    </Link>
                </div>
            </header>

            {/* Footer for mobile devices */}
            <footer className={styles.mobileFooter}>
                <Link to="/" className={styles.mobileIcon}>
                    <img src="/homeicon.svg" alt="Home Icon" />
                </Link>
                <Link to="/discover" className={styles.mobileIcon}>
                    <img src="/discovericon.svg" alt="Discover Icon" />
                </Link>
                <Link to="/map" className={styles.mobileIcon}>
                    <img src="/map.svg" alt="Map Icon" />
                </Link>
                <Link to="/events" className={styles.mobileIcon}>
                    <img src="/event.svg" alt="Event Icon" />
                </Link>
                <Link to="/contact" className={styles.mobileIcon}>
                    <img src="/contact.svg" alt="Contact Icon" />
                </Link>
            </footer>
        </>
    );
};

export default React.memo(Header);
