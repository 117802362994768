import React from 'react';
import styles from './styles/footer.module.css'; // Import the CSS Module
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className={styles.parentdiv}>
        <footer className={styles.footer}>
          <div className={styles.logoSection}>
            <img src="/logo.png" alt="Company Logo" className={styles.logo} />
            <p className={styles.companyDescription}>
              Discover Izmir in all its splendor with us. From its rich history and vibrant culture to the best local experiences, we bring you a wonderful guide to everything Izmir has to offer!
            </p>
            <div className={styles.socialMediaLinks}>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaInstagram /></a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaFacebook /></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaTwitter /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className={styles.socialLink}><FaLinkedin /></a>
            </div>
          </div>
          <div className={styles.companyInfo}>
            <h3 className={styles.header}>Company</h3>
            <ul className={styles.list}>
              <li className={styles.listItem}><Link to="/about">About</Link></li>
              <li className={styles.listItem}><Link to="/features">Features</Link></li>
              <li className={styles.listItem}><Link to="/career">Career</Link></li>
            </ul>
          </div>
          <div className={styles.helpInfo}>
            <h3 className={styles.header}>Help</h3>
            <ul className={styles.list}>
              <li className={styles.listItem}><Link to="/support">Customer Support</Link></li>
              <li className={styles.listItem}><Link to="/terms">Terms & Conditions</Link></li>
              <li className={styles.listItem}><Link to="/privacypolicy">Privacy Policy</Link></li>
            </ul>
          </div>
        </footer>
        <hr className={styles.footerSeparator} />
        <div className={styles.copyright}>
          <p>&copy; {new Date().getFullYear()} Izmir City Vision. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default React.memo(Footer);
