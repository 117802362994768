import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/natures.module.css';
import { FaTree, FaMountain, FaWater, FaLeaf, FaSearch } from 'react-icons/fa';

const Natures = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const natures = [
        { 
            id: 'mountain-spil',
            name: "Spil Mountain", 
            description: "A stunning mountain offering hiking trails, picnic spots, and panoramic views.", 
            icon: <FaMountain /> 
        },
        { 
            id: 'lake-bafa',
            name: "Lake Bafa", 
            description: "A serene lake surrounded by natural beauty, perfect for bird watching and photography.",
            icon: <FaWater /> 
        },
        { 
            id: 'izmir-forest',
            name: "Izmir Forest", 
            description: "A lush green forest providing a peaceful retreat from the city's hustle and bustle.",
            icon: <FaTree /> 
        },
        { 
            id: 'karagol-lake',
            name: "Karagöl Lake", 
            description: "A picturesque lake with a tranquil atmosphere, ideal for picnics and nature walks.", 
            icon: <FaLeaf /> 
        },
    ];

    const filteredNatures = natures.filter(nature =>
        nature.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.natureContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Natures</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for a natural attraction..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredNatures.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.natureList : styles.natureListListMode}>
                        {filteredNatures.map((nature, index) => (
                            <Link to={`/nature/${nature.id}`} key={index} className={viewMode === "detailed" ? styles.natureItem : styles.natureItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.natureIconWrapper}>
                                            <div className={styles.natureIcon}>{nature.icon}</div>
                                        </div>
                                        <div className={styles.natureContent}>
                                            <h2 className={styles.natureName}>{nature.name}</h2>
                                            <p className={styles.natureDescription}>{nature.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.natureIconList}>{nature.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.natureNameList}>{nature.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any natural attractions that match your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Natures;
