import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import Home from './pages/Home';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Support from './pages/Support';
import Career from './pages/Career';
import Features from './pages/Features';
import Contact from './pages/Contact';
import Food from './pages/categories/Food';
import FoodDetail from './pages/FoodDetail';
import Events from './pages/categories/Events';
import EventDetail from './pages/EventDetail';
import Places from './pages/categories/Places';
import PlaceDetail from './pages/PlaceDetail';
import History from './pages/categories/History';
import HistoryDetail from './pages/HistoryDetail';
import Cultures from './pages/categories/Cultures';
import CultureDetail from './pages/CultureDetail';
import Natures from './pages/categories/Natures';
import NatureDetail from './pages/NatureDetail';
import Activities from './pages/categories/Activities';
import ActivityDetail from './pages/ActivityDetail';
import Futures from './pages/categories/Futures';
import FutureDetail from './pages/FutureDetail';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path ="/terms" element={<Terms />}/>
                <Route path="/support" element={<Support />}/>
                <Route path="/career" element={<Career />}/>
                <Route path="/features" element={<Features />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/categories/food" element={<Food />} />
                <Route path="/food/:id" element={<FoodDetail />} />
                <Route path="/categories/events" element={<Events />} />
                <Route path="/event/:id" element={<EventDetail />} />
                <Route path="/categories/places" element={<Places />}  />
                <Route path="/place/:id" element={<PlaceDetail />} />
                <Route path="/categories/history" element={<History />} />
                <Route path="/history/:id" element={<HistoryDetail />} />
                <Route path="/categories/culture" element={<Cultures />} />
                <Route path="/culture/:id" element={<CultureDetail />} />
                <Route path="/categories/nature" element={<Natures />} />
                <Route path="/nature/:id" element={<NatureDetail />} />
                <Route path="/categories/activities" element={<Activities />} />
                <Route path="/activity/:id" element={<ActivityDetail />} />
                <Route path="/categories/activities" element={<Activities />} />
                <Route path="/categories/future" element={<Futures />} />
                <Route path="/future/:id" element={<FutureDetail />} />
                <Route path="" element={<Header />}/>
                <Route path="" element= {<Footer />}/>
            </Routes>
        </Router>
    );
};

export default App;
