import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/foodDetail.module.css';
import { FaUtensils, FaPizzaSlice, FaDrumstickBite, FaIceCream } from 'react-icons/fa';

const foodData = [
    { 
        id: 'boyoz', 
        name: "Boyoz", 
        description: "A delicious pastry made with thin layers of dough and typically enjoyed with eggs.", 
        ingredients: ["Flour", "Water", "Salt", "Oil"],
        history: "Boyoz is a traditional pastry from Izmir, with Sephardic Jewish origins. It has become a symbol of the city's cuisine.",
        preparation: "The dough is made by layering thin sheets of dough and then baking it to a golden brown. It is usually served with boiled eggs.",
        funFact: "Boyoz is often eaten for breakfast, and it's commonly paired with a hot cup of Turkish tea.",
        pairing: "Best enjoyed with a side of boiled eggs and a cup of Turkish tea.",
        category: "Pastry",
        relatedFoods: ['kumru'],  // Updated to more contextually relevant foods
        icon: <FaPizzaSlice /> 
    },
    { 
        id: 'kumru', 
        name: "Kumru", 
        description: "A popular sandwich made with a special type of bread, filled with cheese, tomato, and sausage.",
        ingredients: ["Special bread", "Cheese", "Tomato", "Sausage"],
        history: "Kumru is a well-known street food from Izmir, often enjoyed as a quick lunch or snack.",
        preparation: "The sandwich is prepared by toasting the special bread and filling it with cheese, tomato, and sausage.",
        funFact: "Kumru's unique bread is what makes it stand out from other sandwiches.",
        pairing: "Perfect with a glass of cold ayran (a yogurt-based drink).",
        category: "Sandwich",
        relatedFoods: ['boyoz', 'izmir-kofte'],
        icon: <FaUtensils /> 
    },
    { 
        id: 'izmir-kofte', 
        name: "İzmir Köfte", 
        description: "A traditional meatball dish baked with potatoes, tomatoes, and green peppers.", 
        ingredients: ["Minced meat", "Potatoes", "Tomatoes", "Green peppers", "Spices"],
        history: "İzmir Köfte is a staple of Turkish home cooking, particularly in the Aegean region.",
        preparation: "The meatballs are mixed with spices, shaped, and then baked with potatoes, tomatoes, and peppers.",
        funFact: "This dish is often served during family gatherings and special occasions.",
        pairing: "Goes well with rice or bulgur and a side of yogurt.",
        category: "Main Dish",
        relatedFoods: ['kumru'],
        icon: <FaDrumstickBite /> 
    },
    { 
        id: 'sutlu-irmik-helvasi', 
        name: "Sütlü İrmik Helvası", 
        description: "A sweet dessert made with semolina, milk, and sugar, often served with ice cream.", 
        ingredients: ["Semolina", "Milk", "Sugar", "Butter"],
        history: "Sütlü İrmik Helvası is a beloved Turkish dessert, often prepared during religious and festive occasions.",
        preparation: "The semolina is toasted in butter, then cooked with milk and sugar to create a soft, creamy dessert.",
        funFact: "It's a versatile dessert that can be served warm or cold, often topped with ice cream.",
        pairing: "Pairs wonderfully with vanilla ice cream and a sprinkle of cinnamon.",
        category: "Dessert",
        relatedFoods: [],
        icon: <FaIceCream /> 
    },
];

const FoodDetail = () => {
    const { id } = useParams();
    const food = foodData.find(item => item.id === id);

    if (!food) {
        return <div className={styles.foodNotFound}>Food not found</div>;
    }

    // Get related foods based on category
    const relatedFoods = foodData.filter(
        item => food.relatedFoods.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.foodDetailContainer}>
                <div className={styles.foodContent}>
                    <h1 className={styles.foodName}>
                        {food.icon} {food.name}
                    </h1>
                    <p className={styles.foodDescription}>{food.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Ingredients</h2>
                            <ul className={styles.ingredientList}>
                                {food.ingredients.map((ingredient, index) => (
                                    <li key={index} className={styles.ingredientItem}>{ingredient}</li>
                                ))}
                            </ul>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.foodHistory}>{food.history}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Preparation</h2>
                            <p className={styles.foodPreparation}>{food.preparation}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.foodFunFact}>{food.funFact}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Pairing</h2>
                            <p className={styles.foodPairing}>{food.pairing}</p>
                        </div>
                        {relatedFoods.length > 0 && (
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Foods</h2>
                                <ul className={styles.relatedFoodsList}>
                                    {relatedFoods.map((relatedFood, index) => (
                                        <li key={index} className={styles.relatedFoodItem}>
                                            <Link to={`/food/${relatedFood.id}`}>
                                                {relatedFood.icon} {relatedFood.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FoodDetail;
