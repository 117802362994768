import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/food.module.css';
import { FaUtensils, FaPizzaSlice, FaDrumstickBite, FaIceCream, FaSearch } from 'react-icons/fa';

import boyozImage from './boyoz.jfif';
import kumruImage from './kumru.jfif';
import kofteImage from './izmirköfte.jfif';
import irmikImage from './irmik.jfif';

const Food = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const foods = [
        { 
            id: 'boyoz',
            name: "Boyoz", 
            description: "A delicious pastry made with thin layers of dough and typically enjoyed with eggs.", 
            image: boyozImage,
            icon: <FaPizzaSlice /> 
        },
        { 
            id: 'kumru',
            name: "Kumru", 
            description: "A popular sandwich made with a special type of bread, filled with cheese, tomato, and sausage.",
            image: kumruImage,
            icon: <FaUtensils /> 
        },
        { 
            id: 'izmir-kofte',
            name: "İzmir Köfte", 
            description: "A traditional meatball dish baked with potatoes, tomatoes, and green peppers.", 
            image: kofteImage,
            icon: <FaDrumstickBite /> 
        },
        { 
            id: 'sutlu-irmik-helvasi',
            name: "Sütlü İrmik Helvası", 
            description: "A sweet dessert made with semolina, milk, and sugar, often served with ice cream.", 
            image: irmikImage,
            icon: <FaIceCream /> 
        },
    ];

    const filteredFoods = foods.filter(food =>
        food.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.foodContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Food</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for a food..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredFoods.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.foodList : styles.foodListListMode}>
                        {filteredFoods.map((food, index) => (
                            <Link to={`/food/${food.id}`} key={index} className={viewMode === "detailed" ? styles.foodItem : styles.foodItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.foodImageWrapper}>
                                            {food.image ? (
                                                <img src={food.image} alt={food.name} className={styles.foodImage} />
                                            ) : (
                                                <div className={styles.foodIcon}>{food.icon}</div>
                                            )}
                                        </div>
                                        <div className={styles.foodContent}>
                                            <h2 className={styles.foodName}>{food.name}</h2>
                                            <p className={styles.foodDescription}>{food.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.foodIconList}>{food.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.foodNameList}>{food.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any food that matches your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Food;
