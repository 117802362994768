import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/futures.module.css';
import { FaRobot, FaLightbulb, FaCity, FaChalkboardTeacher, FaSearch } from 'react-icons/fa';

const Futures = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const futures = [
        { 
            id: 'smart-city',
            name: "Smart City Initiatives", 
            description: "Izmir's efforts to become a leading smart city through technology and innovation.", 
            icon: <FaCity /> 
        },
        { 
            id: 'education',
            name: "Future of Education", 
            description: "Exploring the future of education with new technologies in Izmir.",
            icon: <FaChalkboardTeacher /> 
        },
        { 
            id: 'ai',
            name: "AI in Healthcare", 
            description: "How artificial intelligence is transforming healthcare in Izmir.",
            icon: <FaRobot /> 
        },
        { 
            id: 'sustainable-energy',
            name: "Sustainable Energy Projects", 
            description: "Izmir's push towards sustainable and renewable energy sources.", 
            icon: <FaLightbulb /> 
        },
    ];

    const filteredFutures = futures.filter(future =>
        future.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.futureContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Futures</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for a future..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredFutures.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.futureList : styles.futureListListMode}>
                        {filteredFutures.map((future, index) => (
                            <Link to={`/future/${future.id}`} key={index} className={viewMode === "detailed" ? styles.futureItem : styles.futureItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.futureIconWrapper}>
                                            <div className={styles.futureIcon}>{future.icon}</div>
                                        </div>
                                        <div className={styles.futureContent}>
                                            <h2 className={styles.futureName}>{future.name}</h2>
                                            <p className={styles.futureDescription}>{future.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.futureIconList}>{future.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.futureNameList}>{future.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any future that matches your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Futures;
