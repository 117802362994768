import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/eventDetail.module.css';
import { FaCalendarAlt, FaMusic, FaFilm, FaTheaterMasks } from 'react-icons/fa';

const eventData = [
    { 
        id: 'concert', 
        name: "Concert at Agora", 
        description: "A live concert featuring popular bands and artists.", 
        schedule: "August 30th, 2024 at 7:00 PM",
        location: "Agora Open Air Theater, Izmir",
        history: "This annual concert series has been a staple in Izmir's cultural calendar for over a decade.",
        funFact: "Agora's unique acoustics make it a favorite venue for both artists and audiences.",
        category: "Music",
        relatedEvents: ['film-festival'],
        icon: <FaMusic /> 
    },
    { 
        id: 'film-festival', 
        name: "Izmir Film Festival", 
        description: "A celebration of international films with screenings across the city.",
        schedule: "September 5th - 10th, 2024",
        location: "Various locations, Izmir",
        history: "The Izmir Film Festival attracts filmmakers and enthusiasts from around the world.",
        funFact: "The festival often premieres films that go on to win major awards.",
        category: "Film",
        relatedEvents: ['concert', 'theater-play'],
        icon: <FaFilm /> 
    },
    { 
        id: 'theater-play', 
        name: "Theater Play: Hamlet", 
        description: "A modern adaptation of Shakespeare's Hamlet.",
        schedule: "October 15th, 2024 at 8:00 PM",
        location: "Izmir State Theater",
        history: "This adaptation of Hamlet has been praised for its innovative approach.",
        funFact: "The director used modern technology to enhance the play's dramatic effects.",
        category: "Theater",
        relatedEvents: ['film-festival'],
        icon: <FaTheaterMasks /> 
    },
    { 
        id: 'cultural-fair', 
        name: "Cultural Fair", 
        description: "An exhibition showcasing the rich culture of the Aegean region.",
        schedule: "November 20th - 25th, 2024",
        location: "Izmir International Fairgrounds",
        history: "The Cultural Fair has been held annually since 1950.",
        funFact: "It's one of the oldest and most popular events in Izmir.",
        category: "Culture",
        relatedEvents: [],
        icon: <FaCalendarAlt /> 
    },
];

const EventDetail = () => {
    const { id } = useParams();
    const event = eventData.find(item => item.id === id);

    if (!event) {
        return <div className={styles.eventNotFound}>Event not found</div>;
    }

    // Get related events based on category
    const relatedEvents = eventData.filter(
        item => event.relatedEvents.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.eventDetailContainer}>
                <div className={styles.eventContent}>
                    <h1 className={styles.eventName}>
                        {event.icon} {event.name}
                    </h1>
                    <p className={styles.eventDescription}>{event.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Schedule</h2>
                            <p className={styles.eventSchedule}>{event.schedule}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.eventLocation}>{event.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.eventHistory}>{event.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.eventFunFact}>{event.funFact}</p>
                        </div>
                    </div>
                    {relatedEvents.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Events</h2>
                                <ul className={styles.relatedEventsList}>
                                    {relatedEvents.map((relatedEvent, index) => (
                                        <li key={index} className={styles.relatedEventItem}>
                                            <Link to={`/event/${relatedEvent.id}`}>
                                                {relatedEvent.icon} {relatedEvent.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default EventDetail;
