import React from 'react';
import styles from './styles/features.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaCity, FaMapMarkedAlt, FaUsers, FaLightbulb, FaGlobe, FaUtensils, FaHotel } from 'react-icons/fa';
import izmirskyline from './styles/izmirskyline.webp';

const Features = () => {
  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>Discover Our Features</h2>
        <p className={styles.heroSubheading}>
          Explore the unique features that make Izmir City Vision the ultimate guide to this beautiful city.
        </p>
      </div>

      <div className={styles.featuresContent}>
        <div className={styles.featureItem}>
          <div className={styles.featureIconWrapper}>
            <FaCity className={styles.featureIcon} />
          </div>
          <h3 className={styles.featureHeading}>Comprehensive City Guide</h3>
          <p className={styles.featureDescription}>
            Get in-depth information about Izmir’s landmarks, neighborhoods, and hidden gems. Our comprehensive city guide helps you navigate through the city with ease.
          </p>
          <button className={styles.learnMoreButton}>Learn More</button>
        </div>

        <div className={styles.featureItem}>
          <div className={styles.featureIconWrapper}>
            <FaMapMarkedAlt className={styles.featureIcon} />
          </div>
          <h3 className={styles.featureHeading}>Interactive Maps</h3>
          <p className={styles.featureDescription}>
            Explore Izmir with our interactive maps, which offer detailed routes, points of interest, and travel tips tailored to your preferences.
          </p>
          <button className={styles.learnMoreButton}>Learn More</button>
        </div>

        <div className={styles.featureItem}>
          <div className={styles.featureIconWrapper}>
            <FaUsers className={styles.featureIcon} />
          </div>
          <h3 className={styles.featureHeading}>Community Engagement</h3>
          <p className={styles.featureDescription}>
            Join a vibrant community of locals and travelers. Share your experiences, get recommendations, and participate in local events.
          </p>
          <button className={styles.learnMoreButton}>Learn More</button>
        </div>

        <div className={styles.featureItem}>
          <div className={styles.featureIconWrapper}>
            <FaLightbulb className={styles.featureIcon} />
          </div>
          <h3 className={styles.featureHeading}>Expert Tips & Insights</h3>
          <p className={styles.featureDescription}>
            Benefit from insider knowledge with expert tips and insights that help you experience Izmir like a true local.
          </p>
          <button className={styles.learnMoreButton}>Learn More</button>
        </div>
      </div>

      <div className={styles.visualSection}>
        <img
          src={izmirskyline}
          alt="Izmir Skyline"
          className={styles.visualImage}
        />
        <div className={styles.visualContent}>
          <h3 className={styles.visualHeading}>A City That Never Sleeps</h3>
          <p className={styles.visualDescription}>
            Discover the lively culture, rich history, and vibrant nightlife that Izmir has to offer. From day to night, there's always something exciting to explore.
          </p>
        </div>
      </div>

      <div className={styles.additionalFeatures}>
        <h2 className={styles.additionalHeading}>More Features</h2>
        <div className={styles.additionalFeatureGrid}>
          <div className={styles.additionalFeatureItem}>
            <FaGlobe className={styles.additionalFeatureIcon} />
            <h4>Customizable Itineraries</h4>
            <p>Plan your perfect trip with our customizable itineraries that cater to your interests and time frame.</p>
          </div>
          <div className={styles.additionalFeatureItem}>
            <FaUtensils className={styles.additionalFeatureIcon} />
            <h4>Local Cuisine Guides</h4>
            <p>Explore the culinary delights of Izmir with our guides to the best local restaurants and must-try dishes.</p>
          </div>
          <div className={styles.additionalFeatureItem}>
            <FaHotel className={styles.additionalFeatureIcon} />
            <h4>Accommodation Reviews</h4>
            <p>Find the best places to stay with our honest reviews of hotels, hostels, and Airbnb options in the city.</p>
          </div>
          <div className={styles.additionalFeatureItem}>
            <FaMapMarkedAlt className={styles.additionalFeatureIcon} />
            <h4>Event Calendar</h4>
            <p>Stay up-to-date with the latest events and festivals happening in Izmir throughout the year.</p>
          </div>
        </div>
      </div>

      <div className={styles.testimonialsSection}>
        <h2 className={styles.testimonialsHeading}>What People Are Saying</h2>
        <div className={styles.testimonialItem}>
          <p>"Izmir City Vision has been a lifesaver on our trip! The interactive maps and local tips made exploring so much easier."</p>
          <p className={styles.testimonialAuthor}>— Jane Doe, Traveler</p>
        </div>
        <div className={styles.testimonialItem}>
          <p>"The community engagement feature allowed us to connect with locals and discover hidden gems that we wouldn't have found otherwise."</p>
          <p className={styles.testimonialAuthor}>— John Smith, Visitor</p>
        </div>
      </div>

      <div className={styles.ctaSection}>
        <h2 className={styles.ctaHeading}>Start Your Izmir Adventure</h2>
        <button className={styles.ctaButton}>Get Started</button>
      </div>

      <Footer />
    </>
  );
};

export default Features;
