import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/events.module.css';
import { FaCalendarAlt, FaMusic, FaFilm, FaTheaterMasks, FaSearch } from 'react-icons/fa';

const Events = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const events = [
        { 
            id: 'concert',
            name: "Concert at Agora", 
            description: "A live concert featuring popular bands and artists.", 
            icon: <FaMusic /> 
        },
        { 
            id: 'film-festival',
            name: "Izmir Film Festival", 
            description: "A celebration of international films with screenings across the city.",
            icon: <FaFilm /> 
        },
        { 
            id: 'theater-play',
            name: "Theater Play: Hamlet", 
            description: "A modern adaptation of Shakespeare's Hamlet.",
            icon: <FaTheaterMasks /> 
        },
        { 
            id: 'cultural-fair',
            name: "Cultural Fair", 
            description: "An exhibition showcasing the rich culture of the Aegean region.", 
            icon: <FaCalendarAlt /> 
        },
    ];

    const filteredEvents = events.filter(event =>
        event.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.eventContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Events</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for an event..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredEvents.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.eventList : styles.eventListListMode}>
                        {filteredEvents.map((event, index) => (
                            <Link to={`/event/${event.id}`} key={index} className={viewMode === "detailed" ? styles.eventItem : styles.eventItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.eventIconWrapper}>
                                            <div className={styles.eventIcon}>{event.icon}</div>
                                        </div>
                                        <div className={styles.eventContent}>
                                            <h2 className={styles.eventName}>{event.name}</h2>
                                            <p className={styles.eventDescription}>{event.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.eventIconList}>{event.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.eventNameList}>{event.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any event that matches your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Events;
