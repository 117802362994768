import React from 'react';
import styles from './styles/privacypolicy.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>Privacy Policy</h2>
        <p className={styles.heroSubheading}>
          Your privacy is important to us. Learn how we collect, use, and protect your information.
        </p>
      </div>
      <div className={styles.privacyPolicyContainer}>
        <p className={styles.paragraph}>
          This Privacy Policy explains how Izmir City Vision, a private firm promoting the city of Izmir, collects, uses, and safeguards your personal information when you visit our website, <a href="https://www.izmircityvision.com" className={styles.link}>www.izmircityvision.com</a>, and any other sites we own and operate.
        </p>

        <h2 className={styles.subheading}>1. Information We Collect</h2>
        <p className={styles.paragraph}>
          We collect a variety of information to better serve our visitors and improve the promotion of Izmir. The types of information we collect include:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Personal Information:</strong> This includes data such as your name, email address, phone number, and any other information you provide when you subscribe to our newsletter, fill out a contact form, or engage with us in any other way.
          </li>
          <li className={styles.listItem}>
            <strong>Usage Data:</strong> We collect information about your interaction with our website. This may include your IP address, browser type, device information, the pages you visit, the time and date of your visit, and other diagnostic data.
          </li>
          <li className={styles.listItem}>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor the activity on our website and store certain information. Cookies are files with a small amount of data that are sent to your browser from a website and stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
          </li>
        </ul>

        <h2 className={styles.subheading}>2. How We Use Your Information</h2>
        <p className={styles.paragraph}>
          The information we collect is used in the following ways:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>To Provide and Maintain Our Service:</strong> We use your information to operate and maintain our website, including to monitor the usage of our Service and to ensure the security and stability of our systems.
          </li>
          <li className={styles.listItem}>
            <strong>To Communicate With You:</strong> We use your contact information to send you newsletters, promotional materials, and other communications that may be of interest to you. You can opt out of receiving these communications at any time.
          </li>
          <li className={styles.listItem}>
            <strong>To Improve Our Website:</strong> We analyze usage data to better understand how visitors interact with our website. This helps us to improve the content and functionality of our site, making it more relevant and engaging for our users.
          </li>
          <li className={styles.listItem}>
            <strong>To Comply with Legal Obligations:</strong> We may be required to use or disclose your information to comply with legal obligations, such as responding to legal requests from public authorities or to protect our legal rights.
          </li>
        </ul>

        <h2 className={styles.subheading}>3. Data Security</h2>
        <p className={styles.paragraph}>
          The security of your personal information is important to us. We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, firewalls, and secure socket layer (SSL) technology. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>

        <h2 className={styles.subheading}>4. Data Retention</h2>
        <p className={styles.paragraph}>
          We retain your personal information only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements. When we no longer need your personal information, we will securely delete or anonymize it in accordance with applicable laws.
        </p>

        <h2 className={styles.subheading}>5. Your Data Protection Rights</h2>
        <p className={styles.paragraph}>
          Depending on your location, you may have certain rights regarding your personal information under data protection laws. These may include:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>The Right to Access:</strong> You have the right to request copies of your personal information from us.
          </li>
          <li className={styles.listItem}>
            <strong>The Right to Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.
          </li>
          <li className={styles.listItem}>
            <strong>The Right to Erasure:</strong> You have the right to request that we erase your personal information, under certain conditions.
          </li>
          <li className={styles.listItem}>
            <strong>The Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal information, under certain conditions.
          </li>
          <li className={styles.listItem}>
            <strong>The Right to Object to Processing:</strong> You have the right to object to our processing of your personal information, under certain conditions.
          </li>
          <li className={styles.listItem}>
            <strong>The Right to Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          </li>
        </ul>
        <p className={styles.paragraph}>
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at <a href="mailto:contact@izmircityvision.com" className={styles.link}>contact@izmircityvision.com</a>.
        </p>

        <h2 className={styles.subheading}>6. Children's Privacy</h2>
        <p className={styles.paragraph}>
          Our website is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have inadvertently received personal information from a child under the age of 13, we will delete such information from our records.
        </p>

        <h2 className={styles.subheading}>7. Changes to This Privacy Policy</h2>
        <p className={styles.paragraph}>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>

        <h2 className={styles.subheading}>8. Contact Us</h2>
        <p className={styles.paragraph}>
          If you have any questions about this Privacy Policy, our practices, or your dealings with our website, please contact us at:
        </p>
        <address className={styles.address}>
          Izmir City Vision<br />
          Alsancak, Şair Eşref Blv. 57 D D:2, 35220 Konak/İzmir<br />
          Izmir, Turkey<br />
          Email: <a href="mailto:contact@izmircityvision.com" className={styles.link}>contact@izmircityvision.com</a>
        </address>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
