import React, { useState, useRef } from 'react';
import styles from './styles/support.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaChevronDown } from 'react-icons/fa';

const Support = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [formMessage, setFormMessage] = useState(null); // State to hold the form submission message
  const [messageType, setMessageType] = useState(''); // State to hold message type (success or error)

  const generalInfoRef = useRef(null);
  const tourismSupportRef = useRef(null);
  const eventsSupportRef = useRef(null);

  const toggleQuestion = (index) => {
    if (openQuestionIndex === index) {
      setOpenQuestionIndex(null); // Close the question if it's already open
    } else {
      setOpenQuestionIndex(index); // Open the clicked question
    }
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch('http://localhost:5000/api/form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
            setFormMessage(data.message);
            setMessageType('success');
            setFormData({ name: '', email: '', subject: '', message: '' });
        } else {
            setFormMessage('Failed to submit the form. Please try again later.');
            setMessageType('error');
        }
    } catch (error) {
        console.error('Error:', error);
        setFormMessage('An error occurred. Please try again later.');
        setMessageType('error');
    }
};

  const faqs = [
    {
      category: "General Information",
      ref: generalInfoRef,
      questions: [
        {
          question: "What is Izmir City Vision?",
          answer: "Izmir City Vision is a platform dedicated to promoting the beautiful city of Izmir. It provides information on the city's history, culture, attractions, and events.",
        },
        {
          question: "How can I get involved with Izmir City Vision?",
          answer: "You can get involved by participating in our events, contributing content, or collaborating with us to promote Izmir. Reach out through our contact page for more details.",
        },
        {
          question: "Can I contribute content to the website?",
          answer: "Yes, we welcome contributions from locals and visitors alike. Whether it's articles, photos, or videos, you can share your experiences and help others discover Izmir.",
        },
        {
          question: "Is there a mobile app for Izmir City Vision?",
          answer: "Currently, we do not have a mobile app, but our website is fully optimized for mobile devices, allowing you to explore Izmir on the go.",
        },
      ],
    },
    {
      category: "Tourism Support",
      ref: tourismSupportRef,
      questions: [
        {
          question: "What are the must-see attractions in Izmir?",
          answer: "Some of the must-see attractions in Izmir include the ancient city of Ephesus, the beautiful seaside promenade of Kordon, and the historic Kemeralti Bazaar.",
        },
        {
          question: "How can I plan my visit to Izmir?",
          answer: "Izmir City Vision offers a variety of resources to help you plan your visit, including suggested itineraries, travel tips, and information on local accommodations and dining.",
        },
        {
          question: "Are there any guided tours available?",
          answer: "Yes, we collaborate with local tour operators to offer guided tours of Izmir's most popular sites. You can find more information on our tours page.",
        },
        {
          question: "What are the best times of year to visit Izmir?",
          answer: "The best times to visit Izmir are during the spring (April to June) and autumn (September to November) when the weather is mild and the city hosts many cultural events.",
        },
        {
          question: "Can I find information on local cuisine?",
          answer: "Absolutely! We have a dedicated section on Izmir's rich culinary heritage, including recommendations for the best local restaurants and must-try dishes.",
        },
      ],
    },
    {
      category: "Cultural Events & Activities",
      ref: eventsSupportRef,
      questions: [
        {
          question: "What cultural events are happening in Izmir?",
          answer: "Izmir hosts numerous cultural events throughout the year, including music festivals, art exhibitions, and traditional celebrations. Check our events calendar for the latest updates.",
        },
        {
          question: "How can I participate in local events?",
          answer: "You can participate in local events by registering through our website or directly contacting the event organizers. Details are provided on our events page.",
        },
        {
          question: "Are there any family-friendly activities in Izmir?",
          answer: "Yes, Izmir offers a wide range of family-friendly activities, including visits to parks, museums, and historical sites. We highlight these activities in our family section.",
        },
        {
          question: "Can I attend workshops or classes in Izmir?",
          answer: "Yes, there are various workshops and classes available in Izmir, ranging from cooking classes to art workshops. We list these opportunities on our cultural activities page.",
        },
      ],
    },
  ];

  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>How can we help?</h2>
        <p className={styles.heroSubheading}>We are here to assist you with anything you need to know about Izmir.</p>
      </div>

      <div className={styles.supportOptions}>
        <div className={styles.supportCard}>
          <h3 className={styles.cardHeading}>General Information</h3>
          <p className={styles.cardDescription}>
            Learn more about Izmir City Vision and how you can get involved.
          </p>
          <button className={styles.supportButton} onClick={() => scrollToRef(generalInfoRef)}>Get Support</button>
        </div>
        <div className={styles.supportCard}>
          <h3 className={styles.cardHeading}>Tourism Support</h3>
          <p className={styles.cardDescription}>
            Find out about the best attractions, tours, and tips for visiting Izmir.
          </p>
          <button className={styles.supportButton} onClick={() => scrollToRef(tourismSupportRef)}>Get Support</button>
        </div>
        <div className={styles.supportCard}>
          <h3 className={styles.cardHeading}>Cultural Events</h3>
          <p className={styles.cardDescription}>
            Discover the cultural events and activities happening in Izmir.
          </p>
          <button className={styles.supportButton} onClick={() => scrollToRef(eventsSupportRef)}>Get Support</button>
        </div>
      </div>

      <div className={styles.faqSection}>
        <h2 className={styles.faqHeading}>Frequently Asked Questions</h2>
        {faqs.map((faqCategory, categoryIndex) => (
          <div key={categoryIndex} ref={faqCategory.ref} className={styles.faqCategory}>
            <h3 className={styles.faqCategoryHeading}>{faqCategory.category}</h3>
            {faqCategory.questions.map((faq, index) => (
              <div key={index} className={styles.faqItem} onClick={() => toggleQuestion(`${categoryIndex}-${index}`)}>
                <div className={styles.faqQuestionContainer}>
                  <h3 className={styles.faqQuestion}>{faq.question}</h3>
                  <div className={`${styles.iconContainer} ${openQuestionIndex === `${categoryIndex}-${index}` ? styles.iconOpen : ''}`}>
                    <FaChevronDown className={styles.icon} />
                  </div>
                </div>
                {openQuestionIndex === `${categoryIndex}-${index}` && (
                  <p className={styles.faqAnswer}>{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.contactFormSection}>
        <h2 className={styles.contactFormHeading}>Still Have Questions? Get in Touch!</h2>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" className={styles.submitButton}>Send Message</button>
        </form>
        {formMessage && (
          <div className={`${styles.formMessage} ${styles[messageType]}`}>
            {formMessage}
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Support;
