import React, { useState, useEffect } from 'react';
import styles from './styles/timeline.module.css';

const timelineData = [
    { year: "2018", title: "Creation", description: "Establishment", color: "#FF5733" },
    { year: "2019", title: "Acquisition", description: "Addition", color: "#33FF57" },
    { year: "2020", title: "Software", description: "Focus", color: "#3357FF" },
    { year: "2021", title: "Mobile App", description: "Expansion", color: "#FF33A6" },
    { year: "2022", title: "Web Upgrade", description: "Expansion", color: "#FFBD33" },
    { year: "2023", title: "Remote Working", description: "Staff Morale", color: "#8E44AD" },
];

const Timeline = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Trigger animation on component mount
        setIsVisible(true);
    }, []);

    return (
        <div className={`${styles.timelineContainer} ${isVisible ? styles.visible : ''}`}>
            <h2 className={styles.timelineTitle}>Company History</h2>
            <div className={styles.timeline}>
                {timelineData.map((event, index) => (
                    <div className={`${styles.timelineItem} ${isVisible ? styles.fadeIn : ''}`} key={index}>
                        <div className={styles.timelineDescription}>
                            <div className={styles.title}>{event.title}</div>
                            <div className={styles.desc}>{event.description}</div>
                        </div>
                        <div
                            className={styles.timelineCircle}
                            style={{
                                backgroundColor: event.color,
                            }}
                        >
                            <span className={styles.year}>{event.year}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Timeline;
