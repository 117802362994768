import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/cultures.module.css';
import { FaGuitar, FaPalette, FaGlobe, FaUsers, FaSearch } from 'react-icons/fa';

const Cultures = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const cultures = [
        { 
            id: 'traditional-dance',
            name: "Traditional Dance Performances", 
            description: "Experience the vibrant traditional dances of the Aegean region, showcasing the rich cultural heritage of Izmir.", 
            icon: <FaGuitar /> 
        },
        { 
            id: 'folk-music',
            name: "Folk Music Concerts", 
            description: "Live performances of traditional Turkish folk music, featuring local instruments and artists.",
            icon: <FaPalette /> 
        },
        { 
            id: 'handicrafts',
            name: "Traditional Handicrafts", 
            description: "Explore the intricate craftsmanship of Izmir's traditional artisans, including ceramics, weaving, and more.",
            icon: <FaGlobe /> 
        },
        { 
            id: 'cultural-festivals',
            name: "Cultural Festivals", 
            description: "Annual festivals celebrating the diverse cultures and traditions of Izmir.", 
            icon: <FaUsers /> 
        },
    ];

    const filteredCultures = cultures.filter(culture =>
        culture.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.cultureContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Cultures</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for a culture..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredCultures.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.cultureList : styles.cultureListListMode}>
                        {filteredCultures.map((culture, index) => (
                            <Link to={`/culture/${culture.id}`} key={index} className={viewMode === "detailed" ? styles.cultureItem : styles.cultureItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.cultureIconWrapper}>
                                            <div className={styles.cultureIcon}>{culture.icon}</div>
                                        </div>
                                        <div className={styles.cultureContent}>
                                            <h2 className={styles.cultureName}>{culture.name}</h2>
                                            <p className={styles.cultureDescription}>{culture.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.cultureIconList}>{culture.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.cultureNameList}>{culture.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any culture that matches your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Cultures;
