import React from 'react';
import styles from './styles/terms.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Terms = () => {
  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>Terms and Conditions</h2>
        <p className={styles.heroSubheading}>
          These terms and conditions outline the rules and regulations for the use of our website.
        </p>
      </div>
      <div className={styles.termsContainer}>
        <p className={styles.paragraph}>
          Welcome to Izmir City Vision. These terms and conditions outline the rules and regulations for the use of our website, <a href="https://www.izmircityvision.com" className={styles.link}>www.izmircityvision.com</a>.
        </p>

        <h2 className={styles.subheading}>1. Acceptance of Terms</h2>
        <p className={styles.paragraph}>
          By accessing and using this website, you accept and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use this website.
        </p>

        <h2 className={styles.subheading}>2. Intellectual Property Rights</h2>
        <p className={styles.paragraph}>
          Unless otherwise stated, Izmir City Vision and/or its licensors own the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may access this from Izmir City Vision for your own personal use, subject to restrictions set in these terms and conditions.
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>You must not republish material from this website without prior written consent.</li>
          <li className={styles.listItem}>You must not sell, rent, or sub-license material from this website.</li>
          <li className={styles.listItem}>You must not reproduce, duplicate, or copy material from this website.</li>
          <li className={styles.listItem}>You must not redistribute content from Izmir City Vision unless content is specifically made for redistribution.</li>
        </ul>

        <h2 className={styles.subheading}>3. User-Generated Content</h2>
        <p className={styles.paragraph}>
          Certain parts of this website may offer the opportunity for users to post and exchange opinions and information. Izmir City Vision does not filter, edit, publish, or review user-generated content prior to its presence on the website. User-generated content does not reflect the views and opinions of Izmir City Vision, its agents, or affiliates.
        </p>
        <p className={styles.paragraph}>
          We reserve the right to monitor all user-generated content and to remove any content that we consider inappropriate, offensive, or in breach of these terms and conditions.
        </p>

        <h2 className={styles.subheading}>4. Limitation of Liability</h2>
        <p className={styles.paragraph}>
          To the fullest extent permitted by applicable law, Izmir City Vision shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Your use or inability to use the website.</li>
          <li className={styles.listItem}>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
          <li className={styles.listItem}>Any interruption or cessation of transmission to or from our website.</li>
          <li className={styles.listItem}>Any bugs, viruses, trojans, or the like that may be transmitted to or through our website by any third party.</li>
          <li className={styles.listItem}>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the website.</li>
        </ul>

        <h2 className={styles.subheading}>5. Indemnification</h2>
        <p className={styles.paragraph}>
          You agree to indemnify and hold harmless Izmir City Vision, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Your use of and access to the website.</li>
          <li className={styles.listItem}>Your violation of any term of these terms and conditions.</li>
          <li className={styles.listItem}>Your violation of any third-party right, including without limitation any copyright, property, or privacy right.</li>
          <li className={styles.listItem}>Any claim that your user-generated content caused damage to a third party.</li>
        </ul>

        <h2 className={styles.subheading}>6. Termination</h2>
        <p className={styles.paragraph}>
          We may terminate or suspend your access to our website immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these terms and conditions.
        </p>

        <h2 className={styles.subheading}>7. Governing Law</h2>
        <p className={styles.paragraph}>
          These terms and conditions are governed by and construed in accordance with the laws of Turkey, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
        </p>

        <h2 className={styles.subheading}>8. Changes to These Terms</h2>
        <p className={styles.paragraph}>
          Izmir City Vision reserves the right, at our sole discretion, to modify or replace these terms and conditions at any time. We will notify you of any changes by posting the new terms and conditions on this page. What constitutes a material change will be determined at our sole discretion.
        </p>

        <h2 className={styles.subheading}>9. Contact Us</h2>
        <p className={styles.paragraph}>
          If you have any questions about these Terms and Conditions, please contact us at:
        </p>
        <address className={styles.address}>
          Izmir City Vision<br />
          Alsancak, Şair Eşref Blv. 57 D D:2, 35220 Konak/İzmir<br />
          Izmir, Turkey<br />
          Email: <a href="mailto:contact@izmircityvision.com" className={styles.link}>contact@izmircityvision.com</a>
        </address>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
