import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/placeDetail.module.css';
import { FaLandmark, FaTree, FaUmbrellaBeach } from 'react-icons/fa';

const placeData = [
    { 
        id: 'ancient-city', 
        name: "Ancient City of Ephesus", 
        description: "One of the most famous ancient cities in Turkey.", 
        hours: "Open daily from 8:00 AM to 6:00 PM",
        location: "Selçuk, Izmir",
        history: "Ephesus was an ancient Greek city on the coast of Ionia, three kilometers southwest of present-day Selçuk in Izmir Province, Turkey.",
        funFact: "Ephesus was home to one of the Seven Wonders of the Ancient World—the Temple of Artemis.",
        category: "Historic Site",
        relatedPlaces: ['kordon'],
        icon: <FaLandmark /> 
    },
    { 
        id: 'kordon', 
        name: "Kordon", 
        description: "A popular seaside promenade in Izmir, known for its cafes and scenic views.",
        hours: "Open 24 hours",
        location: "Alsancak, Izmir",
        history: "Kordon has been a central part of Izmir’s urban life for centuries, beloved by locals and tourists alike.",
        funFact: "Kordon is the perfect place to catch a beautiful sunset over the Aegean Sea.",
        category: "Promenade",
        relatedPlaces: ['ancient-city', 'kemeralti'],
        icon: <FaUmbrellaBeach /> 
    },
    { 
        id: 'kemeralti', 
        name: "Kemeraltı Bazaar", 
        description: "A historic market area offering a variety of goods and local crafts.",
        hours: "Open Monday to Saturday from 9:00 AM to 7:00 PM",
        location: "Konak, Izmir",
        history: "Kemeraltı is one of the oldest and largest bazaars in Turkey, dating back to the 17th century.",
        funFact: "You can find almost anything in Kemeraltı, from traditional Turkish delights to modern electronics.",
        category: "Market",
        relatedPlaces: ['kordon'],
        icon: <FaLandmark /> 
    },
    { 
        id: 'izmir-wildlife-park', 
        name: "Izmir Wildlife Park", 
        description: "A large park home to many species of animals, ideal for family outings.",
        hours: "Open daily from 9:00 AM to 5:00 PM",
        location: "Çiğli, Izmir",
        history: "Izmir Wildlife Park was established in 2008 and covers an area of 425 acres.",
        funFact: "The park is home to over 1,500 animals from 120 different species.",
        category: "Nature Park",
        relatedPlaces: [],
        icon: <FaTree /> 
    },
];

const PlaceDetail = () => {
    const { id } = useParams();
    const place = placeData.find(item => item.id === id);

    if (!place) {
        return <div className={styles.placeNotFound}>Place not found</div>;
    }

    // Get related places based on category
    const relatedPlaces = placeData.filter(
        item => place.relatedPlaces.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.placeDetailContainer}>
                <div className={styles.placeContent}>
                    <h1 className={styles.placeName}>
                        {place.icon} {place.name}
                    </h1>
                    <p className={styles.placeDescription}>{place.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Hours</h2>
                            <p className={styles.placeHours}>{place.hours}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.placeLocation}>{place.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.placeHistory}>{place.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.placeFunFact}>{place.funFact}</p>
                        </div>
                    </div>
                    {relatedPlaces.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Places</h2>
                                <ul className={styles.relatedPlacesList}>
                                    {relatedPlaces.map((relatedPlace, index) => (
                                        <li key={index} className={styles.relatedPlaceItem}>
                                            <Link to={`/place/${relatedPlace.id}`}>
                                                {relatedPlace.icon} {relatedPlace.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PlaceDetail;
