import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/cultureDetail.module.css';
import { FaPalette, FaGlobe, FaUsers, FaGuitar } from 'react-icons/fa';

const cultureData = [
    { 
        id: 'traditional-dance', 
        name: "Traditional Dance Performances", 
        description: "Experience the vibrant traditional dances of the Aegean region, showcasing the rich cultural heritage of Izmir.", 
        schedule: "Every Saturday evening at 8:00 PM",
        location: "Cultural Center, Izmir",
        history: "These dances have been passed down through generations, reflecting the history and traditions of the Aegean people.",
        funFact: "The 'Zeybek' dance, performed by one or two dancers, is particularly popular and is a symbol of bravery and heroism.",
        category: "Dance",
        relatedCulture: ['folk-music'],
        icon: <FaGuitar /> 
    },
    { 
        id: 'folk-music', 
        name: "Folk Music Concerts", 
        description: "Live performances of traditional Turkish folk music, featuring local instruments and artists.",
        schedule: "First Friday of every month at 7:30 PM",
        location: "Izmir Music Hall",
        history: "Folk music in Izmir is deeply rooted in the history of the region, with influences from both Turkish and Greek cultures.",
        funFact: "Many of the folk songs performed are hundreds of years old and have been preserved in their original form.",
        category: "Music",
        relatedCulture: ['traditional-dance', 'handicrafts'],
        icon: <FaPalette /> 
    },
    { 
        id: 'handicrafts', 
        name: "Traditional Handicrafts", 
        description: "Explore the intricate craftsmanship of Izmir's traditional artisans, including ceramics, weaving, and more.",
        schedule: "Exhibition open daily from 10:00 AM to 6:00 PM",
        location: "Izmir Handicrafts Center",
        history: "Izmir has a long history of handicrafts, with many techniques and designs unique to the region.",
        funFact: "Some of the patterns used in Izmir's textiles date back to ancient times and have been passed down through generations.",
        category: "Crafts",
        relatedCulture: ['folk-music'],
        icon: <FaGlobe /> 
    },
    { 
        id: 'cultural-festivals', 
        name: "Cultural Festivals", 
        description: "Annual festivals celebrating the diverse cultures and traditions of Izmir.",
        schedule: "Various dates throughout the year",
        location: "Multiple locations across Izmir",
        history: "These festivals bring together people from all walks of life to celebrate and share their cultural heritage.",
        funFact: "The Izmir International Festival is one of the most popular, featuring events ranging from music to dance to food.",
        category: "Festival",
        relatedCulture: [],
        icon: <FaUsers /> 
    },
];

const CultureDetail = () => {
    const { id } = useParams();
    const culture = cultureData.find(item => item.id === id);

    if (!culture) {
        return <div className={styles.cultureNotFound}>Culture not found</div>;
    }

    // Get related culture based on category
    const relatedCulture = cultureData.filter(
        item => culture.relatedCulture.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.cultureDetailContainer}>
                <div className={styles.cultureContent}>
                    <h1 className={styles.cultureName}>
                        {culture.icon} {culture.name}
                    </h1>
                    <p className={styles.cultureDescription}>{culture.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Schedule</h2>
                            <p className={styles.cultureSchedule}>{culture.schedule}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.cultureLocation}>{culture.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.cultureHistory}>{culture.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.cultureFunFact}>{culture.funFact}</p>
                        </div>
                    </div>
                    {relatedCulture.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Cultural Aspects</h2>
                                <ul className={styles.relatedCultureList}>
                                    {relatedCulture.map((related, index) => (
                                        <li key={index} className={styles.relatedCultureItem}>
                                            <Link to={`/culture/${related.id}`}>
                                                {related.icon} {related.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CultureDetail;
