import React, { useState, useRef } from 'react';
import styles from './styles/about.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Timeline from '../components/Timeline';
import aboutImage from './styles/activities.webp'; // Adjust the path as necessary
import viator from './styles/viator.png';
import tripadvisor from './styles/tripadvisor.png';
import bokun from './styles/bokun.png';
import getyourguide from './styles/getyourguide.png';
import civitatis from './styles/civitatis.png';
import videoabout from './styles/videoabout.mp4';
import { FaPlay, FaBullseye, FaEye, FaHeart, FaUsers, FaHistory } from 'react-icons/fa'; // Import additional icons
import { useInView } from 'react-intersection-observer';
import { IoMdClose } from 'react-icons/io'; 

const About = () => {
  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: teamRef, inView: teamInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: historyRef, inView: historyInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: brandsRef, inView: brandsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const valuesSectionRef = useRef(null);
  const { ref: valuesRef, inView: valuesInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const scrollToValuesSection = () => {
    valuesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Header />
      <div className={styles.aboutContainer}>
        <main className={styles.main}>
          <section
            ref={aboutRef}
            className={`${styles.aboutSection} ${aboutInView ? styles.fadeIn : ''}`}
          >
            <h1 className={styles.title}>
              INTRODUCING <span className={styles.highlight}>IZMIR</span>
            </h1>
            <p className={styles.text}>
              We are dedicated to showcasing Izmir's vibrant culture and heritage, providing authentic experiences and unforgettable memories for every visitor.
            </p>
            <div className={styles.buttons}>
              <button className={styles.getStarted} onClick={scrollToValuesSection}>Get Started</button>
              <div className={styles.watchVideo} onClick={openPopup}>
                <FaPlay className={styles.playIcon} />
                <span>Watch Video</span>
              </div>
            </div>
          </section>
          <section
            ref={teamRef}
            className={`${styles.teamSection} ${teamInView ? styles.slideIn : ''}`}
          >
            <div className={styles.teamImage}>
              <img src={aboutImage} alt="Team" />
            </div>
          </section>
        </main>

        {/* History Section */}
        <section
          ref={historyRef}
          className={`${styles.historySection} ${historyInView ? styles.fadeIn : ''}`}
        >
          <Timeline />
        </section>

        <section
          ref={brandsRef}
          className={`${styles.brandsSection} ${brandsInView ? styles.zoomIn : ''}`}
        >
          <h2 className={styles.brandsTitle}>Our Partners</h2>
          <div className={styles.brands}>
            <img src={viator} alt="Viator" className={styles.brandLogo} />
            <img src={tripadvisor} alt="TripAdvisor" className={styles.brandLogo} />
            <img src={bokun} alt="Bokun" className={styles.brandLogo} />
            <img src={getyourguide} alt="GetYourGuide" className={styles.brandLogo} />
            <img src={civitatis} alt="Civitatis" className={styles.brandLogo} />
          </div>
        </section>

        <section
          ref={(node) => {
            valuesRef(node);
            valuesSectionRef.current = node;
          }}
          className={`${styles.valuesSection} ${valuesInView ? styles.fadeIn : ''}`}
        >
          <div className={styles.valueBox}>
            <h3><FaBullseye className={styles.icon} /> Our Mission</h3>
            <p>Our mission is to unveil the hidden gems and vibrant culture of Izmir, offering visitors an authentic and immersive experience.</p>
          </div>
          <div className={styles.valueBox}>
            <h3><FaEye className={styles.icon} /> Our Vision</h3>
            <p>Our vision is to position Izmir as a premier global destination, celebrated for its rich history and stunning landscapes.</p>
          </div>
          <div className={styles.valueBox}>
            <h3><FaHeart className={styles.icon} /> Our Values</h3>
            <p>Our values are rooted in authenticity, excellence, innovation, community, and sustainability.</p>
          </div>
        </section>
      </div>
      <Footer />

      {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <button className={styles.closeButton} onClick={closePopup}>
              <IoMdClose />
            </button>
            <div className={styles.videoContainer}>
              <video width="560" height="315" controls>
                <source src={videoabout} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default About;
