import React, { useState } from 'react';
import styles from './styles/career.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaPaintBrush, FaCode, FaBullhorn, FaBriefcase } from 'react-icons/fa';
import backgroundjob from './images/backgroundjob.svg'; // Import the background image

const Career = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formMessage, setFormMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setFormMessage(data.message);
        setMessageType('success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setFormMessage('Failed to submit the form. Please try again later.');
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setFormMessage('An error occurred. Please try again later.');
      setMessageType('error');
    }
  };

  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>Join Our Team</h2>
        <p className={styles.heroSubheading}>
          Explore exciting career opportunities at Izmir City Vision.
        </p>
      </div>

      <div className={styles.hiringAreas}>
        <div className={styles.hiringCard}>
          <FaPaintBrush className={styles.icon} />
          <h3 className={styles.cardHeading}>Product Design</h3>
          <p className={styles.cardDescription}>
            Shape the future of design at Izmir City Vision by creating intuitive, beautiful, and user-centered products.
          </p>
        </div>
        <div className={styles.hiringCard}>
          <FaCode className={styles.icon} />
          <h3 className={styles.cardHeading}>Product Development</h3>
          <p className={styles.cardDescription}>
            Drive innovation and development of high-quality products that enhance our users' experience.
          </p>
        </div>
        <div className={styles.hiringCard}>
          <FaBullhorn className={styles.icon} />
          <h3 className={styles.cardHeading}>Marketing</h3>
          <p className={styles.cardDescription}>
            Spread the word and promote Izmir City Vision to the world through strategic and creative marketing efforts.
          </p>
        </div>
      </div>

      <div className={styles.jobListingsSection}>
        <h2 className={styles.sectionHeading}>Current Job Openings</h2>
        <div
          className={styles.jobListing}
          style={{
            backgroundImage: `url(${backgroundjob})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <FaBriefcase className={styles.jobIcon} />
          <div className={styles.jobDetails}>
            <h3 className={styles.jobTitle}>Senior Product Designer</h3>
            <p className={styles.jobLocation}>Location: Izmir, Turkey</p>
            <p className={styles.jobDescription}>
              Lead the design team and work on exciting projects to shape the future of our product designs.
            </p>
            <button className={styles.applyButton}>Apply Now</button>
          </div>
        </div>
        <div
          className={styles.jobListing}
          style={{
            backgroundImage: `url(${backgroundjob})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <FaBriefcase className={styles.jobIcon} />
          <div className={styles.jobDetails}>
            <h3 className={styles.jobTitle}>Full Stack Developer</h3>
            <p className={styles.jobLocation}>Location: Remote</p>
            <p className={styles.jobDescription}>
              Join our development team and help us build scalable, high-quality applications.
            </p>
            <button className={styles.applyButton}>Apply Now</button>
          </div>
        </div>
        <div
          className={styles.jobListing}
          style={{
            backgroundImage: `url(${backgroundjob})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <FaBriefcase className={styles.jobIcon} />
          <div className={styles.jobDetails}>
            <h3 className={styles.jobTitle}>Marketing Specialist</h3>
            <p className={styles.jobLocation}>Location: Izmir, Turkey</p>
            <p className={styles.jobDescription}>
              Help us reach a wider audience by developing and executing innovative marketing strategies.
            </p>
            <button className={styles.applyButton}>Apply Now</button>
          </div>
        </div>
      </div>

      <div className={styles.contactFormSection}>
        <h2 className={styles.contactFormHeading}>Apply or Ask a Question</h2>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" className={styles.submitButton}>Send Message</button>
        </form>
        {formMessage && (
          <div className={`${styles.formMessage} ${styles[messageType]}`}>
            {formMessage}
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Career;
