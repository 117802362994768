import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/eventDetail.module.css';
import { FaCity, FaRobot, FaLightbulb, FaChalkboardTeacher } from 'react-icons/fa';

const futureData = [
    { 
        id: 'smart-city', 
        name: "Smart City Initiatives", 
        description: "Izmir's efforts to become a leading smart city through technology and innovation.", 
        schedule: "Ongoing",
        location: "Various locations across Izmir",
        history: "Izmir has been investing in smart city projects to enhance urban living and improve sustainability.",
        funFact: "Izmir's smart traffic system has reduced travel time by 20% in the city center.",
        category: "Innovation",
        relatedFutures: ['ai-healthcare'],
        icon: <FaCity /> 
    },
    { 
        id: 'ai', 
        name: "AI in Healthcare", 
        description: "How artificial intelligence is transforming healthcare in Izmir.",
        schedule: "2025 Launch",
        location: "Healthcare facilities across Izmir",
        history: "Izmir's healthcare system is embracing AI to improve patient outcomes and reduce costs.",
        funFact: "AI diagnostics in Izmir's hospitals have achieved 95% accuracy in early disease detection.",
        category: "Healthcare",
        relatedFutures: ['smart-city', 'sustainable-energy'],
        icon: <FaRobot /> 
    },
    { 
        id: 'sustainable-energy', 
        name: "Sustainable Energy Projects", 
        description: "Izmir's push towards sustainable and renewable energy sources.",
        schedule: "2023-2030",
        location: "Renewable energy plants across the Aegean region",
        history: "Izmir is a leader in renewable energy, with major investments in wind and solar power.",
        funFact: "Izmir aims to power 50% of its energy needs from renewable sources by 2030.",
        category: "Energy",
        relatedFutures: ['ai-healthcare'],
        icon: <FaLightbulb /> 
    },
    { 
        id: 'education', 
        name: "Future of Education", 
        description: "Exploring the future of education with new technologies in Izmir.",
        schedule: "2024 Pilot Program",
        location: "Schools and universities across Izmir",
        history: "Izmir is pioneering digital classrooms and AI-driven personalized learning.",
        funFact: "Izmir's virtual classrooms can accommodate up to 10,000 students simultaneously.",
        category: "Education",
        relatedFutures: [],
        icon: <FaChalkboardTeacher /> 
    },
];

const FutureDetail = () => {
    const { id } = useParams();
    const future = futureData.find(item => item.id === id);

    if (!future) {
        return <div className={styles.eventNotFound}>Future not found</div>;
    }

    // Get related futures based on category
    const relatedFutures = futureData.filter(
        item => future.relatedFutures.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.eventDetailContainer}>
                <div className={styles.eventContent}>
                    <h1 className={styles.eventName}>
                        {future.icon} {future.name}
                    </h1>
                    <p className={styles.eventDescription}>{future.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Schedule</h2>
                            <p className={styles.eventSchedule}>{future.schedule}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.eventLocation}>{future.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.eventHistory}>{future.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.eventFunFact}>{future.funFact}</p>
                        </div>
                    </div>
                    {relatedFutures.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Futures</h2>
                                <ul className={styles.relatedEventsList}>
                                    {relatedFutures.map((relatedFuture, index) => (
                                        <li key={index} className={styles.relatedEventItem}>
                                            <Link to={`/future/${relatedFuture.id}`}>
                                                {relatedFuture.icon} {relatedFuture.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FutureDetail;
