import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/activityDetail.module.css';
import { FaHiking, FaBiking, FaSwimmer, FaUmbrellaBeach } from 'react-icons/fa';

const activityData = [
    { 
        id: 'hiking', 
        name: "Hiking at Spil Mountain", 
        description: "Explore the beautiful trails of Spil Mountain.", 
        schedule: "Every weekend from 6:00 AM to 5:00 PM",
        location: "Spil Mountain National Park, Manisa",
        history: "Spil Mountain has been a popular hiking destination for nature enthusiasts for decades.",
        funFact: "The mountain is home to a variety of wildflowers, including the famous Manisa Tulip.",
        category: "Outdoor",
        relatedActivities: ['biking'],
        icon: <FaHiking /> 
    },
    { 
        id: 'biking', 
        name: "Biking around Izmir", 
        description: "Enjoy a scenic bike ride along Izmir's coastline.",
        schedule: "Daily from 8:00 AM to 8:00 PM",
        location: "Izmir Coastal Road",
        history: "Izmir's coastline offers one of the most picturesque biking routes in Turkey.",
        funFact: "The bike route passes by several historical landmarks.",
        category: "Outdoor",
        relatedActivities: ['hiking', 'swimming'],
        icon: <FaBiking /> 
    },
    { 
        id: 'swimming', 
        name: "Swimming at Cesme Beach", 
        description: "Relax and swim in the clear waters of Cesme.",
        schedule: "Daily from 8:00 AM to 6:00 PM",
        location: "Cesme Beach, Izmir",
        history: "Cesme Beach is renowned for its crystal-clear waters and fine sand.",
        funFact: "Cesme is known for its consistent wind, making it a popular spot for windsurfing.",
        category: "Beach",
        relatedActivities: ['biking'],
        icon: <FaSwimmer /> 
    },
    { 
        id: 'beach-day', 
        name: "Beach Day at Alacati", 
        description: "Spend a sunny day lounging on the sands of Alacati.", 
        schedule: "Daily from 9:00 AM to 7:00 PM",
        location: "Alacati Beach, Izmir",
        history: "Alacati Beach has become a trendy spot for both locals and tourists.",
        funFact: "Alacati is also famous for its vibrant nightlife and boutique hotels.",
        category: "Beach",
        relatedActivities: [],
        icon: <FaUmbrellaBeach /> 
    },
];

const ActivityDetail = () => {
    const { id } = useParams();
    const activity = activityData.find(item => item.id === id);

    if (!activity) {
        return <div className={styles.activityNotFound}>Activity not found</div>;
    }

    // Get related activities based on category
    const relatedActivities = activityData.filter(
        item => activity.relatedActivities.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.activityDetailContainer}>
                <div className={styles.activityContent}>
                    <h1 className={styles.activityName}>
                        {activity.icon} {activity.name}
                    </h1>
                    <p className={styles.activityDescription}>{activity.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Schedule</h2>
                            <p className={styles.activitySchedule}>{activity.schedule}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.activityLocation}>{activity.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.activityHistory}>{activity.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.activityFunFact}>{activity.funFact}</p>
                        </div>
                    </div>
                    {relatedActivities.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Activities</h2>
                                <ul className={styles.relatedActivitiesList}>
                                    {relatedActivities.map((relatedActivity, index) => (
                                        <li key={index} className={styles.relatedActivityItem}>
                                            <Link to={`/activity/${relatedActivity.id}`}>
                                                {relatedActivity.icon} {relatedActivity.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ActivityDetail;
