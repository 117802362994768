import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/natureDetail.module.css';
import { FaTree, FaMountain, FaWater, FaLeaf } from 'react-icons/fa';

const natureData = [
    { 
        id: 'mountain-spil', 
        name: "Spil Mountain", 
        description: "A stunning mountain offering hiking trails, picnic spots, and panoramic views.", 
        location: "Manisa, near Izmir",
        history: "Spil Mountain has a rich history in Greek mythology and is a popular spot for nature lovers.",
        funFact: "The mountain is home to the famous Manisa tulips, a symbol of the region.",
        category: "Mountain",
        relatedNatures: ['lake-bafa'],
        icon: <FaMountain /> 
    },
    { 
        id: 'lake-bafa', 
        name: "Lake Bafa", 
        description: "A serene lake surrounded by natural beauty, perfect for bird watching and photography.",
        location: "Muğla, near Izmir",
        history: "Lake Bafa was once part of the Aegean Sea until it became a lake due to sediment accumulation.",
        funFact: "The lake is a haven for migratory birds and is part of a nature reserve.",
        category: "Lake",
        relatedNatures: ['mountain-spil', 'izmir-forest'],
        icon: <FaWater /> 
    },
    { 
        id: 'izmir-forest', 
        name: "Izmir Forest", 
        description: "A lush green forest providing a peaceful retreat from the city's hustle and bustle.",
        location: "Izmir, Turkey",
        history: "The forest has been preserved for decades and is a favorite spot for locals seeking nature within the city.",
        funFact: "The forest is home to a variety of wildlife, including deer and many species of birds.",
        category: "Forest",
        relatedNatures: ['lake-bafa'],
        icon: <FaTree /> 
    },
    { 
        id: 'karagol-lake', 
        name: "Karagöl Lake", 
        description: "A picturesque lake with a tranquil atmosphere, ideal for picnics and nature walks.",
        location: "Izmir, Turkey",
        history: "Karagöl Lake has inspired many legends and is considered a hidden gem within Izmir.",
        funFact: "The lake's name means 'Black Lake' due to its dark, reflective waters.",
        category: "Lake",
        relatedNatures: [],
        icon: <FaLeaf /> 
    },
];

const NatureDetail = () => {
    const { id } = useParams();
    const nature = natureData.find(item => item.id === id);

    if (!nature) {
        return <div className={styles.natureNotFound}>Nature spot not found</div>;
    }

    // Get related nature spots based on category
    const relatedNatures = natureData.filter(
        item => nature.relatedNatures.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.natureDetailContainer}>
                <div className={styles.natureContent}>
                    <h1 className={styles.natureName}>
                        {nature.icon} {nature.name}
                    </h1>
                    <p className={styles.natureDescription}>{nature.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.natureLocation}>{nature.location}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.natureHistory}>{nature.history}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.natureFunFact}>{nature.funFact}</p>
                        </div>
                    </div>
                    {relatedNatures.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Nature Spots</h2>
                                <ul className={styles.relatedNaturesList}>
                                    {relatedNatures.map((relatedNature, index) => (
                                        <li key={index} className={styles.relatedNatureItem}>
                                            <Link to={`/nature/${relatedNature.id}`}>
                                                {relatedNature.icon} {relatedNature.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NatureDetail;
