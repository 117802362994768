import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from '../styles/places.module.css';
import { FaLandmark, FaTree, FaUmbrellaBeach, FaSearch } from 'react-icons/fa';

const Places = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("detailed");

    const places = [
        { 
            id: 'ancient-city',
            name: "Ancient City of Ephesus", 
            description: "One of the most famous ancient cities in Turkey.", 
            icon: <FaLandmark /> 
        },
        { 
            id: 'kordon',
            name: "Kordon", 
            description: "A popular seaside promenade in Izmir, known for its cafes and scenic views.",
            icon: <FaUmbrellaBeach /> 
        },
        { 
            id: 'kemeralti',
            name: "Kemeraltı Bazaar", 
            description: "A historic market area offering a variety of goods and local crafts.",
            icon: <FaLandmark /> 
        },
        { 
            id: 'izmir-wildlife-park',
            name: "Izmir Wildlife Park", 
            description: "A large park home to many species of animals, ideal for family outings.", 
            icon: <FaTree /> 
        },
    ];

    const filteredPlaces = places.filter(place =>
        place.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className={styles.placeContainer}>
                {/* Breadcrumb Section */}
                <div className={styles.breadcrumb}>
                    <Link to="/" className={styles.breadcrumbLink}>Home</Link>
                    <span className={styles.breadcrumbSeparator}> &gt; </span>
                    <span className={styles.breadcrumbCurrent}>Places</span>
                </div>

                <div className={styles.viewModeContainer}>
                    <div 
                        className={viewMode === "detailed" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("detailed")}
                    >
                        Show In Detail
                    </div>
                    <div 
                        className={viewMode === "list" ? styles.activeButton : styles.viewButton}
                        onClick={() => setViewMode("list")}
                    >
                        Show In List
                    </div>
                </div>
                <div className={styles.searchBarContainer}>
                    <FaSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search for a place..."
                        className={styles.searchBar}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                {filteredPlaces.length > 0 ? (
                    <div className={viewMode === "detailed" ? styles.placeList : styles.placeListListMode}>
                        {filteredPlaces.map((place, index) => (
                            <Link to={`/place/${place.id}`} key={index} className={viewMode === "detailed" ? styles.placeItem : styles.placeItemList}>
                                {viewMode === "detailed" ? (
                                    <>
                                        <div className={styles.placeIconWrapper}>
                                            <div className={styles.placeIcon}>{place.icon}</div>
                                        </div>
                                        <div className={styles.placeContent}>
                                            <h2 className={styles.placeName}>{place.name}</h2>
                                            <p className={styles.placeDescription}>{place.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.placeIconList}>{place.icon}</div>
                                        <div className={styles.listItemContent}>
                                            <h2 className={styles.placeNameList}>{place.name}</h2>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                        <p>We couldn't find any place that matches your search criteria. Please try again with a different term.</p>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Places;
