import React from 'react';
import styles from './styles/contact.module.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <>
      <Header />
      <div className={styles.heroSection}>
        <h2 className={styles.heroHeading}>Get in Touch with Us</h2>
        <p className={styles.heroSubheading}>
          We are here to assist you with any inquiries you may have. Feel free to reach out to us.
        </p>
      </div>

      <div className={styles.contactDetails}>
        <div className={styles.contactItem}>
          <FaPhoneAlt className={styles.contactIcon} />
          <h3 className={styles.contactHeading}>Phone</h3>
          <p className={styles.contactDescription}>+90 544 665 93 33</p>
        </div>
        <div className={styles.contactItem}>
          <FaEnvelope className={styles.contactIcon} />
          <h3 className={styles.contactHeading}>Email</h3>
          <p className={styles.contactDescription}>contact@izmircityvision.com</p>
        </div>
        <div className={styles.contactItem}>
          <FaMapMarkerAlt className={styles.contactIcon} />
          <h3 className={styles.contactHeading}>Location</h3>
          <p className={styles.contactDescription}>Gezenthi Travel Agency, Alsancak, İzmir, Turkey</p>
        </div>
      </div>

      <div className={styles.contactFormSection}>
        <h2 className={styles.contactFormHeading}>Send Us a Message</h2>
        <form className={styles.contactForm}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" className={styles.submitButton}>Send Message</button>
        </form>
      </div>

      <div className={styles.mapSection}>
        <h2 className={styles.mapHeading}>Visit Us at Our Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.3686069475393!2d27.143732!3d38.432953999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdc1085f722e9%3A0xd474e26ca684cdb6!2sGezenthi%20Travel%20Agency!5e0!3m2!1str!2str!4v1724065908207!5m2!1str!2str"
          width="100%"
          height="400"
          style={{ border: 0, borderRadius: '10px' }}
          allowFullScreen=""
          loading="lazy"
          title="Gezenthi Travel Agency Location"
        ></iframe>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
