import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/historyDetail.module.css';
import { FaLandmark, FaMonument, FaBook, FaGavel } from 'react-icons/fa';

const historyData = [
    { 
        id: 'ancient-city', 
        name: "Ancient City of Ephesus", 
        description: "Explore the ruins of one of the most significant ancient cities in the world.", 
        period: "Established in the 10th century BC",
        location: "Selçuk, Izmir",
        history: "Ephesus was an ancient Greek city on the coast of Ionia, and later a major Roman city.",
        funFact: "Ephesus was home to the Temple of Artemis, one of the Seven Wonders of the Ancient World.",
        category: "Archaeological Site",
        relatedHistory: ['library-of-celsus'],
        icon: <FaLandmark /> 
    },
    { 
        id: 'izmir-clock-tower', 
        name: "Izmir Clock Tower", 
        description: "A symbol of the city, built in 1901 and standing tall in Konak Square.",
        period: "Built in 1901",
        location: "Konak Square, Izmir",
        history: "The clock tower was designed by Levantine French architect Raymond Charles Père, and gifted by German Emperor Wilhelm II.",
        funFact: "The clock mechanism was a gift from the German Emperor to Sultan Abdulhamid II.",
        category: "Monument",
        relatedHistory: ['ancient-city'],
        icon: <FaMonument /> 
    },
    { 
        id: 'library-of-celsus', 
        name: "Library of Celsus", 
        description: "One of the most beautiful structures in Ephesus, built in honor of Tiberius Julius Celsus.",
        period: "Completed in 117 AD",
        location: "Ephesus, Izmir",
        history: "The library was built to store 12,000 scrolls and to serve as a monumental tomb for Celsus.",
        funFact: "The library's facade has been extensively restored and is one of the best-preserved ruins.",
        category: "Library",
        relatedHistory: ['ancient-city', 'temple-of-artemis'],
        icon: <FaBook /> 
    },
    { 
        id: 'temple-of-artemis', 
        name: "Temple of Artemis", 
        description: "One of the Seven Wonders of the Ancient World, located in Ephesus.", 
        period: "Completed in 550 BC",
        location: "Ephesus, Izmir",
        history: "The temple was dedicated to the goddess Artemis and was rebuilt multiple times after being destroyed.",
        funFact: "The temple was 115 meters long and supported by 127 columns.",
        category: "Temple",
        relatedHistory: ['library-of-celsus'],
        icon: <FaGavel /> 
    },
];

const HistoryDetail = () => {
    const { id } = useParams();
    const history = historyData.find(item => item.id === id);

    if (!history) {
        return <div className={styles.historyNotFound}>History not found</div>;
    }

    // Get related history based on category
    const relatedHistory = historyData.filter(
        item => history.relatedHistory.includes(item.id)
    );

    return (
        <>
            <Header />
            <div className={styles.historyDetailContainer}>
                <div className={styles.historyContent}>
                    <h1 className={styles.historyName}>
                        {history.icon} {history.name}
                    </h1>
                    <p className={styles.historyDescription}>{history.description}</p>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Period</h2>
                            <p className={styles.historyPeriod}>{history.period}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Location</h2>
                            <p className={styles.historyLocation}>{history.location}</p>
                        </div>
                    </div>
                    <div className={styles.infoGrid}>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>History</h2>
                            <p className={styles.historyHistory}>{history.history}</p>
                        </div>
                        <div className={styles.infoItem}>
                            <h2 className={styles.sectionTitle}>Fun Fact</h2>
                            <p className={styles.historyFunFact}>{history.funFact}</p>
                        </div>
                    </div>
                    {relatedHistory.length > 0 && (
                        <div className={styles.infoGrid}>
                            <div className={styles.infoItem}>
                                <h2 className={styles.sectionTitle}>Related Historical Sites</h2>
                                <ul className={styles.relatedHistoryList}>
                                    {relatedHistory.map((related, index) => (
                                        <li key={index} className={styles.relatedHistoryItem}>
                                            <Link to={`/history/${related.id}`}>
                                                {related.icon} {related.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HistoryDetail;
